export default {
  'menu.dashboard': 'Dashboard',
  'menu.user': 'User',
  'menu.user.center': 'User Center',
  'menu.user.setting': 'User Setting',
  // resource-poll
  'menu.resource-pool': 'Resource Pool',
  'menu.resource-pool.payment': 'Payment',
  'menu.resource-pool.payment.user-order': 'User Order',
  'menu.resource-pool.payment.wallet': 'Wallet',
  'menu.resource-pool.payment.wallet.detail': 'Wallet Detail',
  'menu.resource-pool.payment.verification-order': 'Wallet Verification Order',
  'menu.resource-pool.payment.merchant-order': 'Merchant Order',
  'menu.resource-pool.payment.merchant-order.detail': 'Order Detail',
  'menu.resource-pool.payment.user-wallet.detail': 'User Wallet Detail',
  'menu.resource-pool.payment.user-wallet': 'User Wallet',
  'menu.resource-pool.payment.integral-history': 'Integral History',
  'menu.resource-pool.payment.integral-history.detail':
    'Integral History Detail',
  'menu.resource-pool.payment.order-transaction': 'Order Transaction',
  // operation-maintenance
  'menu.operation-maintenance': 'Operation Maintenance',
  'menu.operation-maintenance.message-center': 'Message Center',
  'menu.operation-maintenance.message-center.alarm-message': 'Alarm Message',
  'menu.operation-maintenance.message-center.alarm-message.detail':
    'Alarm Message Detail',
  'menu.operation-maintenance.alarm-service': 'Alarm Service',
  'menu.operation-maintenance.alarm-service.alarm': 'Alarm',
  'menu.operation-maintenance.alarm-service.alarm.detail': 'Alarm Detail',
  'menu.operation-maintenance.event-center': 'Event Center',
  'menu.operation-maintenance.event-center.audit': 'Audit',
  'menu.operation-maintenance.event-center.audit.detail': 'Audit Detail',
  'menu.operation-maintenance.alarm-configuration': 'Alarm Configuration',
  'menu.operation-maintenance.alarm-configuration.alarm-receiver':
    'Alarm Receiver',
  'menu.operation-maintenance.alarm-configuration.alarm-receiver.detail':
    'Alarm Receiver Detail',
  // management
  'menu.operation-management': 'Operational Management',
  'menu.operation-management.personnel-and-permission':
    'Personnel and Permission',
  'menu.operation-management.personnel-and-permission.user': 'User',
  'menu.operation-management.personnel-and-permission.user.detail':
    'User Detail',
  'menu.operation-management.personnel-and-permission.policy': 'Policy',
  'menu.operation-management.personnel-and-permission.policy.detail':
    'Policy Detail',
  'menu.operation-management.personnel-and-permission.structure': 'Structure',
  'menu.operation-management.personnel-and-permission.structure.detail':
    'Structure Detail',
  'menu.operation-management.personnel-and-permission.user-group': 'User Group',
  'menu.operation-management.personnel-and-permission.user-group.detail':
    'User Group Detail',
  'menu.operation-management.content-management': 'Content Management',
  'menu.operation-management.content-management.user-message': 'User Message',
  'menu.operation-management.content-management.user-message.detail':
    'User Message Detail',
  'menu.operation-management.content-management.article': 'Article',
  'menu.operation-management.content-management.article.detail':
    'Article Detail',
  'menu.operation-management.access-control': 'Access Control',
  'menu.operation-management.access-control.access-key': 'AccessKey',
  'menu.operation-management.access-control.access-key.detail':
    'AccessKey Detail',
  'menu.setting': 'Setting',
  'menu.setting.platform-setting': 'Platform Setting',
  'menu.setting.platform-setting.global-setting': 'Global Setting',
};
