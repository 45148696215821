export default {
  /*
   * 这边字母我已经都分好了，注意看注释，我排了一部分，后续我再补排上，目前没有全部排序完成，你们把新添加的写到对应字母下边即可
   * 注意每个字母下边排序也有顺序，先大写，后小写，再字符串
   * 数字排序也要按按顺序，0-9
   */
  // 数字
  '1 Hour': '1 小时',
  '15 Minutes': '15 分钟',
  '2 Hours': '2 小时',
  '30 Minutes': '30 分钟',
  '5 Minutes': '5 分钟',
  '6 Hours': '6 小时',
  // A
  Abnormal: '异常',
  Admin: '管理员',
  Audit: '审核',
  Alarms: '警告',
  Amount: '数量',
  Account: '账号',
  Administrator: '管理员',
  AcceptableRange: '可收款金额范围',
  AuditDetails: '审计详情',
  All: '总览',
  Attributes: '属性',

  Article: '文章',
  acceptRange: '可接受范围',
  Avenues: '渠道',
  amount: '数量',
  account: '账户',
  Alert: '警告',
  Announcement: '通告',
  'Access Number': '访问数',
  'Acceptable Range': '可接受范围',
  'Alarm Statistics': '警告统计',
  'Alarm Distribution': '警告分布',
  'API Name': 'API名称',
  'Associated Alarms': '关联警告',
  'Administrator increase integral': '管理员增加',
  'Administrator decrease integral': '管理员扣除',
  'Are you sure you want to perform this operation?': '是否确认执行该操作?',
  'Associated Alarm-Receivers': '关联警报接收者',
  'Associated Alarm-Messages': '关联警报信息',
  'Are you sure you want to unbind this user?': '你确定要解除此用户吗',
  'Alarm Status': '警告状态',
  'Alarm Name': '警告名称',
  'Alarm Level': '警告等级',
  'Add Attributes': '添加属性',
  'Advanced Settings': '高级设置',
  'Alarm Type': '警告类型',
  'After Amount': '之后数量',
  'API Request UUID': 'API请求UUID',
  'API Response UUID': 'API响应UUID',
  'Associated User Order': '关联用户订单',
  'Associated Merchant Order': '关联商户订单',
  'After successful payment, the order status will pending change from success':
    '支付成功后，订单状态将自动由 Pending 变为 Success',
  'Are you sure you want to remove this accessKey?': '确定要删除此访问权限？',
  'Are you sure you want to trigger a callback for this order?':
    '“您确定要为此订单触发回调吗?”',
  'Are you sure you want to clear the cache?': '确定要清空缓存吗？',
  // B
  Bank: '银行',
  Base: '基本信息',
  Back: '返回',
  Banner: '标语',
  BasicInformation: '基本信息',
  Banned: '封禁',
  'Batch Change Status': '批量修改状态',
  // C
  Count: '数量',
  Currency: '货币',
  Config: '配置信息',
  Content: '内容',
  Channel: '渠道',
  Customize: '自定义',
  Code: '代码',
  CreatedAt: '创建于',
  ContentMessage: '消息',
  Cover: '封面',
  Create: '创建',
  'Call Back Status Code': '回呼状态码',
  'Customer Service': '客服',
  'Config Information': '配置信息',
  'Confider ID': '委托者编号',
  'CallbackStatus Code': '回调状态码',
  'Clear Cache': '清空缓存',
  'Collection Close': '收款关闭',
  'Collection Open': '收款开启',
  'Basic Settings': '基本设置',
  'Bind User': '绑定用户',
  'Before Amount': '之前的数量',
  'Bank Account': '银行账号',
  'Change Parent': '修改父用户',
  'Channel users': '渠道用户',
  'Confider Name': '信任名称',
  'Confirmed At': '核实于',
  'Create User': '创建用户',
  'Check Code': '校验码',
  'Change the bound user': '更改绑定用户',
  "Click 'YES' to complete the operation": '点击YES完成此操作',
  'Change Type': '修改用户类型',
  'Change Structure': '修改用户组织',
  'Change Password': '修改密码',
  'Confirm Password': '确认密码',
  'Change TransactionId': '修改交易编号',
  'Change User Group': '修改用户组',
  'Change Integral': '修改积分',
  'Create Merchant Order': '创建商户订单',
  'Create a new user order': '新建用户订单',
  'Create Access Policy': '创建访问策略',
  'Change order externalId': '修改订单外部编号',
  'Change user order externalId': '修改订单交易编号',
  'Change Order Transaction Id': '修改交易编号',
  'Change Order Status': '编辑订单状态',
  'Change Transaction ID': '编辑交易编号',
  'Completed At': '完成于',
  // D
  Description: '介绍',
  Detail: '详情',
  Delete: '删除',
  Deducted: '已扣除',
  Direction: '方向',
  Disable: '禁用',
  'Deleted At': '删除时间',
  'Delete User Wallet': '删除用户钱包',
  'Delete User': '删除用户',
  'Delete Message': '删除消息',
  'Delete Article': '删除文章',
  'Delete User Group': '删除用户组',
  'Direct Members': '直接成员',
  'User Daily Order Amount Quantity': '用户日交易金额',
  // E
  Enable: '启用',
  Even: '事件',
  Edit: '编辑',
  Emergent: '紧急',
  Event: '事件',
  Explanation: '解释',
  ExternalOrderId: '外部订单编号',
  'Edit Status': '编辑状态',
  'Edit Limit': '编辑限额',
  'Edit Range': '编辑范围',
  'Editor Name Introduction': '编辑名称简介',
  'Edit Permissions': '编辑权限',
  'Edit Verification Order Status': '编辑验证订单状态',
  'External order identifier, only used for merchants to identify orders themselves.':
    '外部订单识别码，仅用于商户识别订单本身。',
  'Edit user order status': '编辑订单状态',
  'Endpoint Type': '端点类型',
  'External ID': '外部编号',
  'External IP For Creator': '创建订单IP',
  'Edit User Order': '编辑用户订单',
  'External IP For Payer': '付款人IP',
  'Edit Access Key Status': '修改AccessKey状态',
  'Edit Information': '编辑信息',
  'Event Audit': '审计',
  'Event Transaction': '交易',
  // F
  Fail: '已失败',
  Failed: '失败',
  // G
  Global: '全局',
  Group: '所属组',
  'Generate AccessKey': '生成AccessKey',
  'Global-Setting': '全局设置',
  // H
  Hours: '小时',
  'Historical information': '历史信息',
  HistoricalInformation: '历史信息',
  // I
  Input: '输入',
  ID: '编号',
  Integral: '积分',
  INR: '卢比',
  Info: '信息',
  Introduction: '简介',
  Incomplete: '未补全',
  'Integral Status': '积分状态',
  'INR Amount': '金额',
  'Integral History': '支付记录',
  'Integral Amount': '积分变动',
  'Insufficient remaining integral': '剩余积分不足',
  'If this UPI does not belong to your wallet, a new wallet will be created and the UPI will be set to the one you entered':
    '如果此 UPI 不属于您的钱包，将创建一个新钱包，并将 UPI 设置为您输入的那个',
  'If you choose this option,the modification of this status will not be called back to the upstream service.':
    '如果选择此选项，则此状态的修改将不会回调回上游服务。',
  'If the callback status code is 200 or 201, the callback is complete.':
    '回调状态码是200或201则代表回调完成。',
  'If you select more than one user group, the user type will no longer be set automatically':
    '如果选择多个用户组，将不再自动设置用户类型。',
  // J
  // K
  // L
  'Last Operator': '上次操作者',
  'Last Switched At': '最后切换时间',
  'Last Switch Owner At': '上次更改订单所有者',
  'Log Status': '交易日志记录',
  'Last Operator Explanation': '上次操作人信息',
  // M
  Modify: '修改策略',
  Major: '主要',
  Merchant: '商户',
  MerchantOrder: '商户订单',
  Minutes: '分钟',
  'Manually Disabled': '手动暂停',
  'Manual Operation': '手动操作',
  'Manually Integral Return': '手动退回',
  'Merchant Order Automatic': '商户订单自动处理',
  'Modify Basic Information': '编辑基本信息',
  'Modify Article': '修改文章内容',
  'Modify Massage': '修改消息内容',
  'Modify Share Type': '修改共享类型',
  'Modify Status': '编辑状态',
  'Move Wallets Priority': '取消优先级',
  'Merchant Order Edit': '商户订单编辑',
  'Merchant Order Status': '商户订单状态',
  'Modify order basic information': '修改订单基本信息',
  'Merchant Daily Order Amount Quantity': '商户日交易金额',
  // N
  Name: '名称',
  None: '不存在',
  Normal: '正常',
  No: '取消',
  'No Pick Up': '未匹配',
  'No need': '无需扣除',
  // O
  Order: '订单',
  Overview: '总览',
  OrderTransactionId: '外部编号',
  Organization: '组织',
  Send: '支出',
  Operator: '操作者',
  Online: '在线',
  Offline: '离线',
  OrderIntegralAmount: '订单积分数',
  Owner: '拥有者',
  'One Week': '一周',
  'Order Name': '订单名',
  'Operation Description': '操作解释',
  'Operation description cannot be null': '操作解释不能为空',
  'Operator Explanation': '操作者解释',
  'Operation Explanation': '操作说明',
  'Order Amount': '订单金额',
  'Order update time': '订单更新时间',
  'Order Integral Amount': '订单积分数',
  'Number Of Changes': '更改值',
  'Order Success Time': '订单成功时间',
  'Order Status': '订单状态',
  // P
  Password: '密码',
  Phone: '电话号',
  Policies: '策略',
  Promoter: '推广',
  Parent: '父用户',
  Project: '项目',
  Paying: '支付中',
  PaymentWallet: '支付钱包',
  Pending: '等待中',
  Public: '公开',
  Payment: '支付',
  'Payment Wallet IFSC': '付款钱包IFSC',
  'Payment Wallet Bank': '付款钱包银行',
  'Payment Wallet UPI': '付款钱包UPI',
  'Payment Wallet Information': '付款钱包信息',
  'Payment Code': '支付代码',
  'Please Select': '请选择',
  'Please Enter': '请输入',
  'Please Pay': '请付款',
  'Parent Group': '父用户组',
  'Payment wallet address': '支付钱包地址',
  'Please scan the QR code for payment': '请扫描二维码支付',
  'Please make sure that the password is always used twice':
    '请确保两次密码一致',
  'Payment Successful': '支付成功',
  'Payment Failed': '支付失败',
  'Payment Processing': '支付处理中',
  // Q
  'Quota Settings': '配额设置',
  'Quantity Before Change': '变更前数值',
  'Quantity After Change': '变更后数值',
  // R
  Rate: '成功率',
  Resource: '资源',
  ResourceId: '资源编号',
  Returned: '返回',
  Response: '响应',
  Revoke: '撤销',
  'Revoke integral change': '撤销积分更改',
  Receive: '收入',
  Review: '审查',
  Reason: '原因',
  Receiver: '接收者',
  'Raw data time': '原始订单时间',
  'Role Type': '角色类型',
  'Resume Changer': '修改静默时间',
  'resumed At': '恢复时间',
  'Reset Wallets Priority': '设置优先级',
  'Resource Type': '资源类型',
  'Receiving Wallet IFSC': '收款钱包ifsc',
  'Receiving Wallet Bank': '收款钱包银行',
  'Related UPI': '收款地址',
  'Receiving wallet UPI': '收款钱包UPI',
  'Related Orders': '关联订单',
  'Related Messages': '关联消息',
  'Receiving Wallet Information': '收款钱包信息',
  'Recharge Within One Week': '子用户一周内充值',
  'Recharge Within One Month': '子用户一月内充值',
  'Recharge Within One Day': '子用户一天内充值',
  'Read Only': '只读',
  'Remaining Integral': '剩余积分',
  'Reference Number': '参考数',
  // S
  Select: '选择',
  Status: '状态',
  Secret: '密码',
  Success: '已成功',
  Special: '特殊',
  Sender: '发送者',
  Structure: '组织',
  System: '系统',
  State: '状态',
  SetRole: '修改组',
  'stop selling': '停止售卖',
  'Set Silence Period': '设置静默时间',
  'Search Organization': '搜索组织',
  'Simple Order': '普通订单',
  'Supplement Order': '补单订单',
  'Skip Callback': '跳过回调',
  'Structure Edit': '编辑组织',
  'System automatically deducts integral': '系统收到扣除',
  'Sub-User Rebate': '子用户返利',
  'Send status change notification': '发送状态更改通知',
  'Share Type': '共享方式',
  'Save or send the AccessKey pair to the user. If you lose the AccessKey pair, you can create a new one.':
    '保存或发送 AccessKey 给用户。如果丢失了 AccessKey，您可以创建新的 AccessKey。',
  // T
  Type: '类型',
  Total: '全部',
  Tag: '标签',
  Today: '今天',
  'Time Consumed': '消耗时间',
  'Task Result': '任务结果',
  'TabAssociate User Wallet': '关联用户钱包',
  'Transaction ID': '交易编号',
  'Transaction From': '交易来源',
  'Transaction To': '交易目的地',
  'Transaction Information': '交易信息',
  'Three Days': '三日',
  'Trigger Callback': '触发回调',
  'Transaction From Address': '交易发件人地址',
  'Transaction To Address': '交易地址',
  'Transaction Amount': '交易金额',
  'Transaction Block Id': '交易块 ID',
  'Today Pending Orders': '今日待处理订单',
  'Today Succeed Orders': '今日成功订单',
  'Today Limit Amount': '今日限制代收金额',
  'Today Limit Frequency': '今日限制代收次数',
  'Today Order Statistics': '今日订单统计',
  'The amount collected today has reached the upper limit':
    '今天收取的金额已达到上限',
  // U
  User: '用户',
  Unit: '单位',
  UnConfirm: '不确定',
  Unknown: '未知',
  Unverified: '未验证',
  'User has been deleted': '已删除用户',
  'UTR Locking': 'UTR 锁定',
  'Unbind User Order': '解除绑定用户',
  'User Wallet Name': '用户钱包名',
  'User Order': '用户订单',
  'User UUID': '用户UUID',
  'User Message Category Id': '用户消息类别ID',
  'User Wallet Attributes': '用户钱包属性',
  'User Name': '用户名',
  'User ID': '用户ID',
  'User Order Automatic': '用户订单自动处理',
  'User Edit': '编辑用户',
  'User Wallet': '用户钱包',
  'User Type': '用户类型',
  'User Detail': '用户性情',
  'User Group Name': '组名',
  'User Order Status': '用户订单状态',
  'User Group Edit': '编辑用户组',
  'User Group Policies Edit': '组策略修改',
  'User Wallet Type': '用户钱包类型',
  'User Integral': '用户积分',
  "Used to store a user's WebAuthn certificate number or AccessKey":
    '用于存储用户 WebAuthn 证书编号或 AccessKey',
  'User IP': '用户IP',
  'User Wallet Status': '用户钱包状态',
  'User Status': '用户状态',
  'User Online': '用户在线',
  'User Offline': '用户离线',
  'User Wallet Bans': '钱包封禁',
  Upi: 'UPI',
  Utr: 'UTR',
  // V
  Version: '版本',
  'Verification Order': '验证订单',
  // W
  Wallet: '渠道',
  Worker: '员工',
  'Wallet Type': '钱包类型',
  'Wallet Authentication': '钱包认证',
  'When the status changes, the corresponding integral amount of the record will also be refunded or deducted again.':
    '当状态发生变化时，记录的相应积分金额也将再次退还或扣除。',
  'When you modify a user group, the user type is automatically set.':
    '当修改用户组的时候会自动设置用户类型。',
  'Wallet with status of Failed cannot allow to set priority':
    '状态为失败的钱包不允许设置优先级',
  // X
  // Y
  Yes: '确定',
  // Z
};
