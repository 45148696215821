import { Notification } from '@/components/GlobalNotification';
import { Bridge, Generator, Logger } from '@element/oxygen';
import * as UmiMax from '@umijs/max';

setTimeout(() => {
  // 输出产品信息
  Logger.capsule(
    'Two Factor Technology > Forest > Mangrove',
    'Build20250115.JSX',
  );
  // 调整参数
  // > 配置默认数据
  if (!localStorage.getItem("JiShuXin['GlobalSetting']")) {
    localStorage.setItem(
      "JiShuXin['GlobalSetting']",
      JSON.stringify({
        'superPageContainer.showJumper': false,
        'superProTable.useLegacySearch': true,
        'superProTable.legacySearch.defaultCollapsed': false,
      }),
    );
  }
  // > 注入方法
  Object.assign(Bridge, UmiMax);
}, 0);

const { getInitialState, layout, locale, request, rootContainer } =
  Generator.forApp({
    layout: () => ({
      actionsRender: () => [<Notification key="notification" />],
      menuRender: null,
      menu: {
        locale: true,
        type: 'group',
        collapsedShowGroupTitle: true,
      },
    }),
  });

export { getInitialState, layout, locale, request, rootContainer };
