export default {
  'menu.dashboard': '仪表板',
  'menu.user': '用户',
  'menu.user.center': '用户中心',
  'menu.user.setting': '用户设置',
  // resource-poll
  'menu.resource-pool': '资源中心',
  'menu.resource-pool.payment': '支付',
  'menu.resource-pool.payment.user-order': '用户订单',
  'menu.resource-pool.payment.user-order.detail': '用户订单详情',
  'menu.resource-pool.payment.wallet': '支付渠道',
  'menu.resource-pool.payment.wallet.detail': '支付渠道详情',
  'menu.resource-pool.payment.merchant-order': '商户订单',
  'menu.resource-pool.payment.merchant-order.detail': '订单详情',
  'menu.resource-pool.payment.user-wallet': '用户钱包',
  'menu.resource-pool.payment.user-wallet.detail': '用户钱包详情',
  'menu.resource-pool.payment.verification-order': '钱包验证订单',
  'menu.resource-pool.payment.integral-history': '积分记录',
  'menu.resource-pool.payment.integral-history.detail': '积分记录详情',
  'menu.resource-pool.payment.order-transaction': '原始订单记录',
  // operation-maintenance
  'menu.operation-maintenance': '平台运维',
  'menu.operation-maintenance.message-center': '信息中心',
  'menu.operation-maintenance.message-center.alarm-message': '警告信息',
  'menu.operation-maintenance.message-center.alarm-message.detail':
    '警告信息详情',
  'menu.operation-maintenance.alarm-service': '警告服务',
  'menu.operation-maintenance.alarm-service.alarm': '警告',
  'menu.operation-maintenance.alarm-service.alarm.detail': '警告详情',
  'menu.operation-maintenance.event-center': '事件中心',
  'menu.operation-maintenance.event-center.audit': '审计',
  'menu.operation-maintenance.event-center.audit.detail': '审计详情',
  'menu.operation-maintenance.alarm-configuration': '警报配置',
  'menu.operation-maintenance.alarm-configuration.alarm-receiver': '警告接收者',
  'menu.operation-maintenance.alarm-configuration.alarm-receiver.detail':
    '警告接收者详情',
  // management
  'menu.operation-management': '运营管理',
  'menu.operation-management.personnel-and-permission': '人员和权限',
  'menu.operation-management.personnel-and-permission.user': '用户',
  'menu.operation-management.personnel-and-permission.user.detail': '用户详情',
  'menu.operation-management.personnel-and-permission.policy': '策略',
  'menu.operation-management.personnel-and-permission.policy.detail':
    '策略详情',
  'menu.operation-management.personnel-and-permission.structure': '架构',
  'menu.operation-management.personnel-and-permission.structure.detail':
    '架构详情',
  'menu.operation-management.personnel-and-permission.user-group': '用户组',
  'menu.operation-management.personnel-and-permission.user-group.detail':
    '用户组详情',
  'menu.operation-management.content-management': '内容管理',
  'menu.operation-management.content-management.user-message': '通知信息',
  'menu.operation-management.content-management.user-message.detail':
    '通知信息详情',
  'menu.operation-management.content-management.article': '文章',
  'menu.operation-management.content-management.article.detail': '文章详情',
  'menu.operation-management.access-control': '权限控制',
  'menu.operation-management.access-control.access-key': 'AccessKey',
  'menu.operation-management.access-control.access-key.detail': 'AccessKey详情',
  //
  'menu.setting': '设置',
  'menu.setting.platform-setting': '平台设置',
  'menu.setting.platform-setting.global-setting': '全局设置',
};
